<template>
  <div>
    <b-form-select
      v-model="formValue"
      :options="options"
      style="width: 70px; margin-right: 10px;"
    />
    <label>Per page</label>
  </div>
</template>

<script>
import {
  BFormSelect,
} from 'bootstrap-vue'

export default {
  components: {
    BFormSelect,
  },
  props: {
    // Got value from v-model
    value: {
      type: Number,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
  },
  computed: {
    formValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>
