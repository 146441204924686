/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */
import { DATE_FORMAT } from '@/utils/Format'
import moment from 'moment'

export default class Opportunity {
  constructor(data) {
    return {
      id: data.id || '',
      name: data.name || '',
      stage: data.stage_name || '',
      account_management_status: data.account_management_status || '',
      amount: data.amount || '',
      duration: data.duration || '',
      close_date: data.close_date ? moment(data.close_date).format(DATE_FORMAT) : '',
      lastmodified_date: moment(data.sf_last_modified_date).format(DATE_FORMAT) || '',
      planned_start_date: data.planned_start_date ? moment(data.planned_start_date).format(DATE_FORMAT) : '',
      planned_end_date: data.planned_end_date ? moment(data.planned_end_date).format(DATE_FORMAT) : '',
      account_name: data.account_name || '',
      account_manager: data.account_manager || '',
      account_owner: data.account_owner || '',
      amount_inclusive_of_tax: data.amount_inclusive_of_tax || '',
      campaign_code: data.campaign_code || '',
      campaign_id: data.campaign_id || '',
      country: data.country || '',
      management_fee: data.management_fee || '',
      management_fee_type: data.management_fee_type || '',
      stage_name: data.stage_name || '',
      salesforce_link_url: data.salesforce_link_url || '',
      brief_date: data.brief_date ? moment(data.brief_date).format(DATE_FORMAT) : '',
      Legal_Entity__c: data.Legal_Entity__c || '',
    }
  }
}
